import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  DragOverlay,
  defaultDropAnimation
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates, arrayMove } from '@dnd-kit/sortable';
import { getTaskById, updateUsersStatus } from 'views/Clusters/partials/dnd/utils/tasks';
import {
  findBoardSectionContainer,
  initializeBoard
} from 'views/Clusters/partials/dnd/utils/board';
import BoardSection from 'views/Clusters/partials/dnd/BoardSection';
import TaskItem from 'views/Clusters/partials/dnd/TaskItem';
import { setManage } from 'api/manageClusters';
import Switch from 'components/Buttons/Switch';
import { Row } from 'react-bootstrap';
import { BOARD_SECTIONS } from 'views/Clusters/partials/dnd/utils/constants';
import { UNASSIGNED } from './utils/constants';

const contStyle = {
  paddingLeft: 0,
  marginLeft: 0,
  paddingRight: 0,
  marginRight: 0
};

function BoardSectionList({ items, assignedLabelers, jobTypeId }) {
  const [boardSections, setBoardSections] = useState();
  const [tasks, setTasks] = useState();
  const [activeTaskId, setActiveTaskId] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [isQCMode, setIsQCMode] = useState(false);
  const [userSearchVal, setUserSearchVal] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [startContainer, setStartContainer] = useState();

  useEffect(() => {
    if (!items && items.length < 1) return;

    const formatedItemsBoardSections = items
      .filter((item) => !!item.user.first_name)
      .filter((item) => item.permission === 'NORMAL' || item.permission === 'QC')
      .map((item) => ({
        id: item.id.toString(),
        title: `${item.user.first_name} ${item.user.last_name}`,
        description: `${item.user.first_name} ${item.user.last_name}`,
        status: item,
        permission: item.permission
      }));

    setMounted(true);
    const filtered_tasks = updateUsersStatus(formatedItemsBoardSections, assignedLabelers);
    const newFilteredTasks = filtered_tasks.filter((u) => u.status === UNASSIGNED);
    setTasks(filtered_tasks);
    setFilteredUsers(newFilteredTasks);

    const initialisedBoard = initializeBoard(formatedItemsBoardSections);

    setBoardSections(initialisedBoard);
  }, [items, assignedLabelers]);

  useEffect(() => {
    if (!boardSections) return;

    let filtered_tasks = [...boardSections.UNASSIGNED];
    if (isQCMode) {
      filtered_tasks = filtered_tasks.filter((u) => u.permission === 'QC');
    } else {
      filtered_tasks = filtered_tasks.filter((u) => u.permission !== 'QC');
    }

    if (userSearchVal) {
      filtered_tasks = filtered_tasks.filter((user) =>
        user.description.toLowerCase().includes(userSearchVal.toLowerCase())
      );
    }

    setFilteredUsers(filtered_tasks);
  }, [isQCMode, userSearchVal, boardSections]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragStart = ({ active }) => {
    const activeContainer = findBoardSectionContainer(boardSections, active.id);
    setActiveTaskId(active.id);
    setStartContainer(activeContainer);
  };

  const handleDragOver = ({ active, over }) => {
    // Find the containers
    const activeContainer = findBoardSectionContainer(boardSections, active.id);
    const overContainer = findBoardSectionContainer(boardSections, over?.id);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setBoardSections((boardSection) => {
      const activeItems = boardSection[activeContainer];
      const overItems = boardSection[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((item) => item.id === active.id);
      const overIndex = overItems.findIndex((item) => item.id !== over?.id);

      return {
        ...boardSection,
        [activeContainer]: [
          ...boardSection[activeContainer].filter((item) => item.id !== active.id)
        ],
        [overContainer]: [
          ...boardSection[overContainer].slice(0, overIndex),
          boardSections[activeContainer][activeIndex],
          ...boardSection[overContainer].slice(overIndex, boardSection[overContainer].length)
        ]
      };
    });
  };

  const handleDragEnd = ({ active, over }) => {
    const activeContainer = findBoardSectionContainer(boardSections, active.id);
    const overContainer = findBoardSectionContainer(boardSections, over?.id);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    if (startContainer === activeContainer) {
      return;
    }

    const activeIndex = boardSections[activeContainer].findIndex((task) => task.id === active.id);
    const overIndex = boardSections[overContainer].findIndex((task) => task.id === over?.id);

    setBoardSections((boardSection) => ({
      ...boardSection,
      [overContainer]: arrayMove(boardSection[overContainer], activeIndex, overIndex)
    }));

    setManage(jobTypeId, { labeler_id: active.id, assigned_station: overContainer }).then(() => {
      // TO DO  - discuss snackbar on success
    });
    setActiveTaskId(null);
  };

  const dropAnimation = {
    ...defaultDropAnimation
  };

  const task = activeTaskId ? getTaskById(tasks, activeTaskId) : null;

  return (
    mounted &&
    jobTypeId && (
      <Container maxWidth="1600" style={contStyle}>
        <div className="d-flex mb-2 px-0 mx-0 justify-content-end">
          <Switch name={1} checked={isQCMode} setChecked={setIsQCMode} label="Filter on QC Only" />
        </div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}>
          <Row>
            <div className="col-9">
              {!isQCMode && (
                <div className="card">
                  <div className="card-header">
                    <strong>Labeler</strong>
                  </div>
                  <div className="card-body">
                    <Row>
                      <div className="col-12 col-md-6 col-lg-3" key="PRE-FILTERING'">
                        <div>
                          <div className="card">
                            <div className="card-header">{BOARD_SECTIONS['PRE-FILTERING']}</div>
                            <div className="card-body">
                              <BoardSection
                                id="PRE-FILTERING"
                                tasks={boardSections['PRE-FILTERING'].filter(
                                  (u) => u.permission === 'NORMAL'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3" key="CLEANING">
                        <div>
                          <div className="card">
                            <div className="card-header">{BOARD_SECTIONS.CLEANING}</div>
                            <div className="card-body">
                              <BoardSection
                                id="CLEANING"
                                tasks={boardSections.CLEANING.filter(
                                  (u) => u.permission === 'NORMAL'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3" key="IDENTIFICATION">
                        <div>
                          <div className="card">
                            <div className="card-header">{BOARD_SECTIONS.IDENTIFICATION}</div>
                            <div className="card-body">
                              <BoardSection
                                id="IDENTIFICATION"
                                tasks={boardSections.IDENTIFICATION.filter(
                                  (u) => u.permission === 'NORMAL'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3" key="CLUSTER-FINDER">
                        <div>
                          <div className="card">
                            <div className="card-header">{BOARD_SECTIONS['CLUSTER-FINDER']}</div>
                            <div className="card-body">
                              <BoardSection
                                id="CLUSTER-FINDER"
                                tasks={boardSections['CLUSTER-FINDER'].filter(
                                  (u) => u.permission === 'NORMAL'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              )}
              {isQCMode && (
                <div className="card">
                  <div className="card-header">
                    <strong>Reviewer</strong>
                  </div>
                  <div className="card-body">
                    <Row>
                      <div className="col-12 col-md-6 col-lg-4">
                        <div key="PRE-FILTERING">
                          <div className="card">
                            <div className="card-header">{BOARD_SECTIONS['PRE-FILTERING']}</div>
                            <div className="card-body">
                              <BoardSection
                                id="PRE-FILTERING"
                                tasks={boardSections['PRE-FILTERING'].filter(
                                  (u) => u.permission !== 'NORMAL'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4">
                        <div key="CLEANING">
                          <div className="card">
                            <div className="card-header">{BOARD_SECTIONS.CLEANING}</div>
                            <div className="card-body">
                              <BoardSection
                                id="CLEANING"
                                tasks={boardSections.CLEANING.filter(
                                  (u) => u.permission !== 'NORMAL'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4">
                        <div key="IDENTIFICATION">
                          <div className="card">
                            <div className="card-header">{BOARD_SECTIONS.IDENTIFICATION}</div>
                            <div className="card-body">
                              <BoardSection
                                id="IDENTIFICATION"
                                tasks={boardSections.IDENTIFICATION.filter(
                                  (u) => u.permission !== 'NORMAL'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4">
                        <div key="CLUSTER-FINDER">
                          <div className="card">
                            <div className="card-header">{BOARD_SECTIONS['CLUSTER-FINDER']}</div>
                            <div className="card-body">
                              <BoardSection
                                id="CLUSTER-FINDER"
                                tasks={boardSections['CLUSTER-FINDER'].filter(
                                  (u) => u.permission !== 'NORMAL'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4">
                        <div key="FAST-LANE">
                          <div className="card">
                            <div className="card-header">{BOARD_SECTIONS['FAST-LANE']}</div>
                            <div className="card-body">
                              <BoardSection
                                id="FAST-LANE"
                                tasks={boardSections['FAST-LANE'].filter(
                                  (u) => u.permission !== 'NORMAL'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              )}
              <div className="card">
                <div className="card-header">
                  <strong>Status</strong>
                </div>
                <div className="card-body">
                  <Row>
                    <div className="col-md-4 col-sm-12">
                      <div key="PRE-FILTERING">
                        <div className="card">
                          <div className="card-body">
                            <div className='pb-1'>
                              {BOARD_SECTIONS['PRE-FILTERING']}: <b>{assignedLabelers?.in_status['PRE-FILTERING']}</b>
                            </div>
                            <div className='pb-1'>
                              {BOARD_SECTIONS.DISCARDED}: <b>{assignedLabelers?.in_status.DISCARDED}</b>
                            </div>
                            <div className='pb-1'>
                              {BOARD_SECTIONS['FAST-LANE']}: <b>{assignedLabelers?.in_status['FAST-LANE']}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div key="CLEANING">
                        <div className="card">
                          <div className="card-body">
                            <div
                                className='pb-1'>{BOARD_SECTIONS.CLEANING}: <b>{assignedLabelers?.in_status.CLEANING}</b>
                            </div>
                            <div className='pb-1'>
                              {BOARD_SECTIONS.CLEANING_LABELER}: <b>{assignedLabelers?.in_status.CLEANING_LABELER}</b>
                              <span> | </span>
                              <span className="text-nowrap">
                                {BOARD_SECTIONS['CLEANING-QC']}: <b>{assignedLabelers?.in_status['CLEANING-QC']}</b>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div key="IDENTIFICATION">
                        <div className="card">
                          <div className="card-body">
                            <div className='pb-1'>
                              {BOARD_SECTIONS.IDENTIFICATION}: <b>{assignedLabelers?.in_status.IDENTIFICATION}</b>
                            </div>
                            <div className='pb-1'>
                              {BOARD_SECTIONS.IDENTIFICATION_LABELER}: <b>{assignedLabelers?.in_status.IDENTIFICATION_LABELER}</b>
                              <span> | </span>
                              <span className="text-nowrap">
                                {BOARD_SECTIONS['IDENTIFICATION-QC']}: <b>{assignedLabelers?.in_status['IDENTIFICATION-QC']}</b>
                              </span>
                            </div>
                            <div className='pb-1'>
                              {BOARD_SECTIONS.KNOWN}: <b>{assignedLabelers?.in_status.KNOWN}</b>
                              <span> | </span>
                              <span className="text-nowrap">
                                {BOARD_SECTIONS.UNKNOWN}: <b>{assignedLabelers?.in_status.UNKNOWN}</b>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card">
                <div className="card-header">Users</div>
                <div className="card-header">
                  <TextField
                    id="standard-basic"
                    label="Search Users"
                    variant="standard"
                    value={userSearchVal}
                    onChange={(event) => {
                      setUserSearchVal(event.target.value);
                    }}
                  />
                </div>
                <div className="card-body">
                  <section className="labelers-list">
                    <BoardSection id={UNASSIGNED} title={UNASSIGNED} tasks={filteredUsers} />
                  </section>
                </div>
              </div>
            </div>
          </Row>
          <DragOverlay dropAnimation={dropAnimation}>
            {task ? <TaskItem task={task} /> : null}
          </DragOverlay>
        </DndContext>
      </Container>
    )
  );
}

export default BoardSectionList;

import React, { FC, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { HttpStatus, PRIORITIES } from '../../../enums';
import { reProcess } from '../../../api/clusters';
import ConfirmModal from '../../../modals/ConfirmModal';
import SelectStation from '../../../components/Select/SelectStation';
import SelectPriority from '../../../components/Select/SelectPriority';
import ModalWithReProcess from '../../../components/Modal/ModalWithReProcess';
import { ReProcessTO } from '../../../api/api.types';
import { parseCSVReprocessFile } from '../../../helpers/parseCSV';

export interface ReProcessModalsProps {
  showReProcessModal: boolean;
  setShowReProcessModal: (showReProcessModal: boolean) => void;
  handleRequest: (params: any, action?: any) => void;
  parameters: any;
  selectedItems: Array<string>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Array<string>>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  reProcessCSV: boolean;
}

const ReProcessModals: FC<ReProcessModalsProps> = ({
  showReProcessModal,
  setShowReProcessModal,
  handleRequest,
  parameters,
  selectedItems,
  setSelectedItems,
  setErrorMessage,
  reProcessCSV,
}) => {
  const defaultPriority = PRIORITIES[3];
  const CSV_FILE_LIMIT = 10000;
  const [showReProcessModalResult, setShowReProcessModalResult] =
    useState(false);
  const [selectedStation, setSelectedStation] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [selectedPriority, setSelectedPriority] = useState(defaultPriority);
  const [cleanBB_UUID, setCleanBB_UUID] = useState(true);
  const [updatedData, setUpdatedData] = useState<Array<ReProcessTO>>([]);
  const [selectedItemsCSV, setSelectedItemsCSV] = useState<Array<string>>([]);
  const [exceedMaxSizeError, setExceedMaxSizeError] = useState<boolean>(false);

  const updateSelectedClusters = () => {
    setShowReProcessModal(false);
    const params = {
      priority: selectedPriority.value,
      station: selectedStation!.value,
      clean_bb_uuid: cleanBB_UUID,
    };
    const payload = {
      clusters_ids: reProcessCSV ? selectedItemsCSV : selectedItems,
    };

    reProcess(payload, params).then(async (response) => {
      if (response.status === HttpStatus.SUCCESS) {
        const data = await response.json();
        setUpdatedData(data);
        setShowReProcessModalResult(true);
        setSelectedItems([]);
        setSelectedPriority(defaultPriority);
      } else {
        const data = await response.json();
        setErrorMessage(data.detail || 'Error');
      }
    });
  };

  const closeConfirmModal = () => {
    setShowReProcessModal(false);
    setSelectedPriority(defaultPriority);
    setSelectedItemsCSV([]);
    setCleanBB_UUID(true);
    setSelectedStation(null);
    setExceedMaxSizeError(false);
  };

  const handleClose = () => {
    handleRequest(parameters);
    setShowReProcessModalResult(false);
    closeConfirmModal();
  };

  const onChangeFileSCV = () => {
    setSelectedItemsCSV([]);
    setExceedMaxSizeError(false);
    const reader = new FileReader();
    const files = document.getElementById('csvInput') as HTMLInputElement;
    const file = files && files.files && files.files[0];
    if (!file) return;
    reader.onload = () => {
      const parseCSV = parseCSVReprocessFile(reader);
      if (parseCSV.length > CSV_FILE_LIMIT) {
        setExceedMaxSizeError(true);
        files.value = '';
      } else {
        setSelectedItemsCSV(parseCSV);
      }
    };
    reader.readAsBinaryString(file);
  };

  const disabledReprocess = reProcessCSV
    ? !selectedItemsCSV.length || !selectedStation || !selectedPriority
    : !selectedStation || !selectedPriority;

  const examplesCSV = [
    { href: '/CSVExamples/reprocess1.csv', label: 'example1' },
    { href: '/CSVExamples/reprocess2.csv', label: 'example2' },
  ];

  return (
    <>
      <ConfirmModal
        show={showReProcessModal}
        header="Re-process"
        confirmLabel="Re-process"
        cancelLabel="Cancel"
        onConfirm={updateSelectedClusters}
        onCancel={closeConfirmModal}
        disabled={disabledReprocess}>
        {reProcessCSV ? (
          <Row>
            <Col xs={2} className="d-flex align-items-center">
              File:
            </Col>
            <Col xs={10}>
              <input
                accept=".csv"
                id="csvInput"
                onChange={onChangeFileSCV}
                type="file"
                data-testid="csvInput"
              />
            </Col>
            {exceedMaxSizeError && (
              <Col
                xs={{ span: 10, offset: 2 }}
                className="text-danger pt-2"
                data-testid="exceedMaxSizeError">
                The file should contain no more than 10,000 elements. <br />
                If you need more, split it into several parts.
              </Col>
            )}
            <Col xs={{ span: 10, offset: 2 }} className="text-muted pt-2">
              CSV file examples:{' '}
              {examplesCSV.map((el, index) => (
                <span key={el.label}>
                  {!!index && <>, </>}
                  <a href={el.href}>{el.label}</a>
                </span>
              ))}
            </Col>
          </Row>
        ) : (
          <div>
            You are trying to re-process {selectedItems?.length} cluster(s):
          </div>
        )}
        <Row className=" my-3">
          <Col xs={2} className="d-flex align-items-center">
            Station:
          </Col>
          <Col xs={10} md={8}>
            <SelectStation
              selectedStation={selectedStation}
              setSelectedStation={setSelectedStation}
            />
          </Col>
        </Row>
        <Row className=" mt-2">
          <Col xs={2} className="d-flex align-items-center">
            Priority:
          </Col>
          <Col xs={10} md={8}>
            <SelectPriority
              selectedPriority={selectedPriority}
              setSelectedPriority={setSelectedPriority}
              range={[2, 3, 4]}
              isDisabled={false}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} className="form-check pl-3">
            <label
              htmlFor="cleanBB_UUID"
              className="form-check-label pl-0 pr-5">
              <span className="d-inline-block pr-2">clean BB_UUID:</span>
              <input
                name="cleanBB_UUID"
                className="form-check-input"
                type="checkbox"
                onChange={() => setCleanBB_UUID(!cleanBB_UUID)}
                checked={cleanBB_UUID}
                id="cleanBB_UUID"
                data-testid="cleanBB_UUID__checkbox"
              />
              <span className="form-check-sign ml-4" />
            </label>
          </Col>
        </Row>
      </ConfirmModal>
      <ModalWithReProcess
        show={showReProcessModalResult}
        data={updatedData}
        handleClose={handleClose}
      />
    </>
  );
};

export default ReProcessModals;

import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Card, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { getBaseClusters, getExtendedClusters } from '../../api/clusters';
import { getLabelers } from '../../api/user';
import ModalWithImage from '../../components/Modal/ModalWithImage';
import ListPaginator, {
  PAGE_SIZE,
} from '../../components/Paginators/ListPaginator';
import { useUserContext } from '../../contexts/Users';
import ClusterFiltersBase from './ClustersParts/ClusterFiltersBase';
import ClusterFilersExtended from './ClustersParts/ClusterFilersExtended';
import SpinnerButton from '../../components/Buttons/SpinnerButton';
import UserInfoModal from './ClustersParts/UserInfoModal';
import ClusterHistoryModal from './ClustersParts/ClusterHistoryModal';
import GalleryCropsModal from './ClustersParts/GalleryCropsModal';
import ClustersRow from './ClustersParts/ClustersRow';
import ShowSimilarModal from './ClustersParts/ShowSimilarModal';
import BBSearchModal from './ClustersParts/BBSearchModal';
import ReProcessModals from './ClustersParts/ReProcessModals';

function Clusters() {
  const { isAdmin, isViewer } = useUserContext();
  const [tab, setTab] = useState('Base');
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [labelers, setLabelers] = useState([{}]);
  const [reviewers, setRewiewers] = useState([{}]);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [page, setPage] = useState(1);
  const [parameters, setParameters] = useState({});
  const [showReProcessModal, setShowReProcessModal] = useState(false);
  const [reProcessCSV, setReProcessCSV] = useState(false);
  const [prevAction, setPrevAction] = useState(null);
  const [selectedBase, setSelectedBase] = useState(null);
  const [selectedExtended, setSelectedExtended] = useState(null);
  const [paramsCSV, setParamsCSV] = useState({});
  const [errorMessageCSV, setErrorMessageCSV] = useState('');
  const [messageCSVExtended, setMessageCSVExtended] = useState('');
  const [messageCSVBase, setMessageCSVBase] = useState('');
  const [sharedParams, setSharedParams] = useState(false);
  const [isShareDisabled, setIsShareDisabled] = useState(false);
  const [infoCSV, setInfoCSV] = useState({
    isSuccess: false,
    isDisabledButton: true,
  });
  const [searchData, setSearchData] = useState(null);
  const [userInfoState, setUserInfoState] = useState({
    showModal: false,
    userId: undefined,
  });

  const [clusterHistoryState, setClusterHistoryState] = useState({
    showModal: false,
    clusterId: undefined,
  });
  const [galleryCropsState, setGalleryCropsState] = useState({
    showModal: false,
    clusterId: undefined,
  });
  const [showSimilarState, setShowSimilarState] = useState({
    showModal: false,
    item: undefined,
  });
  const [bBSearchModalState, setBBSearchModalState] = useState({
    showModal: false,
    item: undefined,
  });

  const location = useLocation();
  const history = useHistory();
  const currentUrl = window.location.href;

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) => {
      return prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((item) => item !== id)
        : [...prevSelectedItems, id];
    });
  };

  const handleSelectAll = () => {
    const allIds = searchData.map((item) => item.id);
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.length === searchData.length ? [] : allIds,
    );
  };

  const getParams = (selected, action) => {
    const {
      selectedJobType,
      selectedState,
      selectedLabeler,
      subState,
      searchClusterId,
      searchBB,
      selectedReviewer,
      selectedSortOn,
      selectedImportedType,
      selectedPriority,
      isShared,
    } = selected;

    if (!isShared) {
      if (action === 'extended') {
        setSelectedExtended(selected);
      } else {
        setSelectedBase(selected);
      }
    }

    const params = {
      page_size: PAGE_SIZE,
    };
    if (selectedJobType?.value?.project)
      params.project = selectedJobType.value.project;
    if (selectedState?.value) params.state = selectedState.value;
    if (selectedLabeler?.value) params.labeler_id = selectedLabeler.value;
    if (subState?.value) params.substate = subState.value;
    if (selectedPriority?.value) params.priority = selectedPriority.value;
    if (searchClusterId) params.cluster_id = searchClusterId;
    if (searchBB) params.brandbank_uuid = searchBB;
    if (selectedReviewer?.value) params.reviewer_id = selectedReviewer.value;
    if (selectedSortOn) params.ordering = selectedSortOn;
    if (selectedImportedType?.value && selectedImportedType?.value !== 'all')
      params.imported_from_featurestore = selectedImportedType.value;
    if (selected?.page) params.page = selected.page;
    return params;
  };

  const onShareClick = (params, action) => {
    const newParams = { ...getParams(params, action), action };
    const queryParams = new URLSearchParams(newParams).toString();
    const fixURl =
      currentUrl[currentUrl.length - 1] === '#'
        ? currentUrl.slice(0, -1)
        : currentUrl;
    const url = `${fixURl}?${queryParams}`;
    navigator.clipboard.writeText(url);
  };

  const showPreview = (newImageUrl) => {
    setShowModal(true);
    setImageUrl(newImageUrl);
  };

  const handleRequest = useCallback(
    (params, action = prevAction) => {
      const actionFunction =
        action === 'extended' ? getExtendedClusters : getBaseClusters;
      const newParams = getParams(params, action);
      setLoading(true);
      setSearchData(null);
      setParamsCSV({});
      setCount(0);
      setNext(null);
      setPrevious(null);
      actionFunction(newParams).then(async (response) => {
        setLoading(false);
        const data = await response.json();
        if (response.status !== 200) {
          setErrorMessage(data.detail || 'Error');
          return;
        }
        if (data && !data.results) return;

        const newParamsCSV = {
          parametersCSV: {
            ...parameters,
            page: 1,
            page_size: data.count,
            generate_report: true,
          },
          actionFunction:
            action === 'extended' ? getExtendedClusters : getBaseClusters,
        };
        setInfoCSV((prev) => ({
          ...prev,
          isSuccess: false,
        }));
        setParamsCSV(newParamsCSV);
        setErrorMessage('');
        setErrorMessageCSV('');
        const newDataResults = data.results;
        setSearchData(newDataResults);
        setCount(data.count);
        setNext(data.next);
        setPrevious(data.previous);
        setPage(parameters.page);
      });
    },
    [parameters, prevAction],
  );

  const handleRequestCSV = useCallback((params, action) => {
    const actionFunction =
      action === 'extended' ? getExtendedClusters : getBaseClusters;
    const actionFunctionMessage =
      action === 'extended' ? setMessageCSVExtended : setMessageCSVBase;
    const newParams = getParams(params);
    const parametersCSV = {
      ...newParams,
      page: 1,
      generate_report: true,
    };
    delete parametersCSV.page_size;
    setLoading(true);
    actionFunction(parametersCSV).then(async (response) => {
      if (response.status !== 204) {
        actionFunctionMessage('Request CSV Error');
      } else {
        actionFunctionMessage(
          'Your request has been sent, in a few minutes please check e-mail',
        );
      }
      setLoading(false);
    });
  }, []);

  const filterCallback = useCallback(
    (params, action) => {
      if (params?.isCSVRequest) {
        handleRequestCSV(params, action);
        return;
      }
      if (!params?.isShared) {
        setSharedParams(false);
        setIsShareDisabled(false);
      }
      setPrevAction(action);
      setInfoCSV({
        isSuccess: false,
        isDisabledButton: false,
      });
      const newParams = { ...params, page: 1 };
      setSelectedItems([]);
      handleRequest(newParams, action);
      setParameters(newParams, action);
    },
    [handleRequest, handleRequestCSV],
  );

  useEffect(() => {
    getLabelers().then(async (response) => {
      const data = await response.json();
      const allLabelers = data.results;
      const newLabelers = [];
      const newReviewers = [];
      allLabelers.forEach((labeler) => {
        const option = {
          value: labeler.id,
          label: `${labeler.user.username}-${labeler.id}`,
        };
        newLabelers.push(option);
        if (labeler.permission === 'QC') newReviewers.push(option);
        if (labeler.permission === 'STAFF') newReviewers.push(option);
      });
      setLabelers(newLabelers);
      setRewiewers(newReviewers);
    });
    const sharedLink = location.search;

    if (sharedLink) {
      const arrParams = sharedLink.substring(1).split('&');
      const newParam = arrParams.reduce((object, value) => {
        const el = value.split('=');
        return { ...object, [el[0]]: el[1] };
      }, {});
      setSharedParams(newParam);

      filterCallback(
        {
          isShared: true,
          selectedJobType: {
            value: {
              project: newParam.project,
            },
          },
          selectedState: {
            value: newParam.state,
          },
          selectedLabeler: {
            value: newParam.labeler_id,
          },
          subState: {
            value: newParam.substate,
          },
          selectedReviewer: {
            value: newParam.reviewer_id,
          },
          searchBB: newParam.brandbank_uuid,
          searchClusterId: newParam.cluster_id,
          selectedSortOn: newParam.ordering,
          selectedImportedType: {
            value: newParam.imported_from_featurestore,
          },
          page: newParam.page,
        },
        newParam.action,
      );
    }
    setIsShareDisabled(true);
    history.push(location.pathname);
  }, [history, location.pathname, location.search, filterCallback]);

  const loadData = (params) => {
    setPage(params.page);
    handleRequest({ ...parameters, ...params });
  };

  const requestCSV = () => {
    const { actionFunction, parametersCSV } = paramsCSV;
    setLoading(true);
    actionFunction(parametersCSV).then(async (response) => {
      if (response.status !== 204) {
        setErrorMessageCSV('Request CSV Error');
        setLoading(false);
        return;
      }
      setInfoCSV({ isSuccess: true, isDisabledButton: true });
      setLoading(false);
    });
  };

  const onClickButtonShare = () => {
    if (prevAction === 'base') {
      onShareClick({ ...selectedBase }, 'base');
    } else {
      onShareClick({ ...selectedExtended }, 'extended');
    }
  };

  return (
    <div>
      <div>
        <ModalWithImage
          imageUrl={imageUrl}
          showModal={showModal}
          handleClose={() => setShowModal(false)}
        />
        <ReProcessModals
          showReProcessModal={showReProcessModal}
          setShowReProcessModal={setShowReProcessModal}
          handleRequest={handleRequest}
          parameters={parameters}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setErrorMessage={setErrorMessage}
          reProcessCSV={reProcessCSV}
        />
        <Tabs id="controlled-tab" activeKey={tab} onSelect={(k) => setTab(k)}>
          <Tab
            eventKey="Base"
            title="Base filters"
            tabClassName="btn"
            disabled={loading}
          >
            <ClusterFiltersBase
              prevSelected={selectedBase}
              labelers={labelers}
              reviewers={reviewers}
              filterCallback={filterCallback}
              isLoading={loading}
              messageCSVBase={messageCSVBase}
              setMessageCSVBase={setMessageCSVBase}
            />
          </Tab>
          <Tab
            eventKey="Extended"
            title="Extended filters"
            tabClassName="btn"
            disabled={isViewer || loading}
          >
            <ClusterFilersExtended
              prevSelected={selectedExtended}
              labelers={labelers}
              reviewers={reviewers}
              filterCallback={filterCallback}
              isLoading={loading}
              messageCSVExtended={messageCSVExtended}
              setMessageCSVExtended={setMessageCSVExtended}
              onShareClick={onShareClick}
            />
          </Tab>
        </Tabs>
        {sharedParams && (
          <Alert variant="secondary">
            <Alert.Heading as="h4" className="my-2">
              This is the results from shared search with parameters
            </Alert.Heading>
            <button
              aria-hidden
              className="close"
              data-dismiss="alert"
              type="button"
              onClick={() => setSharedParams(null)}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            {sharedParams.project && <div>Project: {sharedParams.project}</div>}
            {sharedParams.state && <div>state: {sharedParams.state}</div>}
            {sharedParams.brandbank_uuid && (
              <div>BB UUID: {sharedParams.brandbank_uuid}</div>
            )}
            {sharedParams.substate && (
              <div>sub State: {sharedParams.substate}</div>
            )}
            {sharedParams.labeler_id && (
              <div>Labeler: {sharedParams.labeler_id}</div>
            )}
            {sharedParams.reviewer_id && (
              <div>Reviewer: {sharedParams.reviewer_id}</div>
            )}
            {sharedParams.cluster_id && (
              <div>Cluster Id: {sharedParams.cluster_id}</div>
            )}
            {sharedParams.imported_from_featurestore && (
              <div>
                Imported from featurestore:{' '}
                {sharedParams.imported_from_featurestore}
              </div>
            )}
          </Alert>
        )}
        {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
        {searchData && (
          <>
            <Card className="card-scroll">
              {loading ? (
                <div className="p-3">
                  <Spinner size="lg" animation="border" role="status" />
                </div>
              ) : (
                <Card.Body className="px-3">
                  {searchData.map((el, index) => (
                    <ClustersRow
                      key={el.id}
                      item={el}
                      index={index}
                      handleCheckboxChange={handleCheckboxChange}
                      showPreview={showPreview}
                      setUserInfoState={setUserInfoState}
                      setClusterHistoryState={setClusterHistoryState}
                      setGalleryCropsState={setGalleryCropsState}
                      setShowSimilarState={setShowSimilarState}
                      setBBSearchModalState={setBBSearchModalState}
                      onShareClick={onShareClick}
                      selectedItems={selectedItems}
                    />
                  ))}
                </Card.Body>
              )}
            </Card>
            <div className="d-flex justify-content-center mt-n3">
              {count > PAGE_SIZE && (
                <ListPaginator
                  total={count}
                  current={page}
                  previous={previous}
                  next={next}
                  changePage={loadData}
                />
              )}
            </div>
            <div className="d-flex justify-content-between mb-3">
              <h4 className="m-0 mt-2">
                Total: {count}{' '}
                <span className="text-nowrap">
                  (Selected: {selectedItems.length})
                </span>
              </h4>

              <div className="text-right  mt-n2">
                {!isViewer && (
                  <>
                    <SpinnerButton
                      disabled={false}
                      className="btn mt-2"
                      onClick={handleSelectAll}
                      isLoading={loading}
                      value={
                        selectedItems.length === searchData.length
                          ? 'Unselect All'
                          : 'Select All'
                      }
                    />
                    <SpinnerButton
                      disabled={!selectedItems.length}
                      className="btn ml-2  mt-2"
                      onClick={() => {
                        setShowReProcessModal(true);
                        setReProcessCSV(false);
                      }}
                      isLoading={loading}
                      value="Re-process selected"
                    />
                    <SpinnerButton
                      disabled={false}
                      className="btn ml-2  mt-2"
                      onClick={() => {
                        setShowReProcessModal(true);
                        setReProcessCSV(true);
                      }}
                      isLoading={loading}
                      value="Re-process from CSV"
                    />
                  </>
                )}
                {(isAdmin || isViewer) && (
                  <SpinnerButton
                    className="btn ml-2  mt-2"
                    onClick={requestCSV}
                    disabled={
                      !paramsCSV?.parameters || infoCSV.isDisabledButton
                    }
                    isLoading={loading}
                    variant="default"
                    value="Request CSV"
                  />
                )}
                <SpinnerButton
                  className="btn ml-2  mt-2"
                  onClick={onClickButtonShare}
                  isLoading={loading}
                  disabled={isShareDisabled}
                  value="Share"
                />
              </div>
            </div>
            {infoCSV.isSuccess && (
              <div className="t-center mb-3">
                Your request has been sent, in a few minutes please check e-mail
              </div>
            )}
            {errorMessageCSV ? (
              <Alert variant="danger" className="mt-0 mb-3">
                {errorMessageCSV}
              </Alert>
            ) : null}
          </>
        )}
      </div>
      <UserInfoModal
        userInfoState={userInfoState}
        setUserInfoState={setUserInfoState}
      />
      <ClusterHistoryModal
        clusterHistoryState={clusterHistoryState}
        setClusterHistoryState={setClusterHistoryState}
      />
      <GalleryCropsModal
        galleryCropsState={galleryCropsState}
        setGalleryCropsState={setGalleryCropsState}
      />
      <ShowSimilarModal
        showSimilarState={showSimilarState}
        setShowSimilarState={setShowSimilarState}
      />
      <BBSearchModal
        bBSearchModalState={bBSearchModalState}
        setBBSearchModalState={setBBSearchModalState}
        setSelectedBase={setSelectedBase}
      />
    </div>
  );
}

export default Clusters;

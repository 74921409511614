export const parseCSVReprocessFile = (reader: FileReader): Array<string> => {
  let result: string[] = [];
  if (reader.result && typeof reader.result === 'string') {
    result = reader.result
      .split(/\r\n|,|\n/)
      .map((el) => el.trim())
      .filter((el) => el.length);
  }
  return result;
};

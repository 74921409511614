// TODO: fixme
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ClusterAction } from 'api/dataLayer';
import ConfirmModal from 'modals/ConfirmModal';
import React, { FC, useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Cluster } from './Cleaning';

const ClusterActionButtons: FC<{
  performAction: (action: ClusterAction, comment?: string) => Promise<void>;
  onAcceptWithMarks: () => void;
  isLabeler: boolean;
  clusterLoaded: boolean;
  clusters: Cluster[];
  hasMarkedCrops: boolean;
  clusterRejected: boolean;
}> = ({
  performAction,
  onAcceptWithMarks,
  isLabeler,
  clusterLoaded,
  clusters,
  hasMarkedCrops,
  clusterRejected,
}) => {
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showSendWithCommentDialog, setShowSendWithCommentDialog] =
    useState(false);
  const [comment, setComment] = useState('');

  const [showConfirmAcceptDialog, setShowConfirmAcceptDialog] = useState(false);

  const disableButton =
    !clusterLoaded || (clusterLoaded && clusters.length > 1);

  /* const rejectCommentRef = useRef<any>(null);
  useEffect(() => {
    rejectCommentRef.current?.focus(); // TODO: Does not fire. Fix.
  }, []); */

  const onConfirmReject = () => {
    setShowRejectDialog(false);
    if (comment) performAction('rejectClusterWithComment', comment);
    else performAction('reject');
  };

  const onConfirmSendWithComment = () => {
    setShowSendWithCommentDialog(false);
    performAction('sendClusterWithComment', comment);
  };

  const commentTooLong = comment.length > 1024;

  const onAccept = () => {
    if (hasMarkedCrops) setShowConfirmAcceptDialog(true);
    else performAction('accept');
  };

  return (
    <>
      {!isLabeler && clusterLoaded && (
        <Button
          disabled={disableButton}
          variant="outline-danger"
          onClick={() => setShowRejectDialog(true)}>
          Reject
        </Button>
      )}
      {isLabeler && clusterRejected && (
        <Button
          disabled={disableButton}
          variant="outline-secondary"
          onClick={() => setShowSendWithCommentDialog(true)}>
          Send With Comment
        </Button>
      )}
      {isLabeler ? (
        <Button
          disabled={disableButton}
          variant="fill"
          onClick={() => performAction('send')}>
          Send
        </Button>
      ) : (
        <Button disabled={disableButton} variant="fill" onClick={onAccept}>
          Accept
        </Button>
      )}
      <ConfirmModal
        header="Reject cluster"
        confirmLabel="Reject Cluster"
        cancelLabel="Cancel"
        show={showRejectDialog}
        disabled={commentTooLong}
        onCancel={() => setShowRejectDialog(false)}
        onConfirm={() => onConfirmReject()}
        size="lg">
        <label>Add a comment (optional)</label>
        <Form>
          <FormControl
            placeholder="Enter text of a comment"
            as="textarea"
            rows={3}
            required
            className="form-range"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {commentTooLong && (
            <div className="error-text pt-2">
              comment can&apos;t be longer than 1024 symbols
            </div>
          )}
        </Form>
      </ConfirmModal>
      <ConfirmModal
        header="Send With Comment"
        confirmLabel="Send Cluster"
        cancelLabel="Cancel"
        show={showSendWithCommentDialog}
        disabled={commentTooLong || !comment}
        onCancel={() => setShowSendWithCommentDialog(false)}
        onConfirm={() => onConfirmSendWithComment()}
        size="lg">
        <label>
          Add a comment
          <span className="error-text">
            <sup>*</sup>
          </span>
        </label>
        <Form>
          <FormControl
            placeholder="Enter text of a comment"
            as="textarea"
            rows={3}
            required
            className="form-range"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {commentTooLong && (
            <div className="error-text pt-2">
              comment can&apos;t be longer than 1024 symbols
            </div>
          )}
        </Form>
      </ConfirmModal>
      <ConfirmModal
        header="Accept Cluster With Marks"
        confirmLabel="Accept"
        cancelLabel="Cancel"
        show={showConfirmAcceptDialog}
        onCancel={() => setShowConfirmAcceptDialog(false)}
        onConfirm={() => {
          setShowConfirmAcceptDialog(false);
          onAcceptWithMarks();
        }}
        size="lg">
        Accepting a cluster with marks to the next station will cause the
        removal of all marks, although the crops will remain in the cluster.
      </ConfirmModal>
    </>
  );
};
ClusterActionButtons.displayName = 'ClusterActionButtons';

export default ClusterActionButtons;

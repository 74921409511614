export const BOARD_SECTIONS = {
  CLEANING: 'Cleaning',
  'CLUSTER-FINDER': 'Cluster Finder',
  IDENTIFICATION: 'Identification',
  'PRE-FILTERING': 'Pre-Filtering',
  UNASSIGNED: 'UNASSIGNED',
  DISCARDED: 'Discarded',
  CLEANING_LABELER: 'Labeler',
  'CLEANING-QC': 'QC',
  IDENTIFICATION_LABELER: 'Labeler',
  'IDENTIFICATION-QC': 'QC',
  KNOWN: 'Known',
  UNKNOWN: 'Unknown',
  'FAST-LANE': 'Fast Lane',
};

export const UNASSIGNED = 'UNASSIGNED';

import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useUserContext } from '../../../contexts/Users';
import { getAuditReportBBS } from '../../../api/auditReport';
import { PAGE_SIZE } from '../../../components/Paginators/ListPaginator';
import {PRIORITIES} from "../../../enums";


function ClustersRow({
  item,
  index,
  handleCheckboxChange,
  showPreview,
  setUserInfoState,
  setClusterHistoryState,
  setGalleryCropsState,
  setShowSimilarState,
  setBBSearchModalState,
  onShareClick,
  selectedItems
}) {
  const { isAdmin } = useUserContext();
  const [label, setLabel] = useState('');

  useEffect(() => {
    if (!item.brandbank_uuid) {
      setLabel('Nothing found');
      return;
    }
    getAuditReportBBS({
      predefined_classes: item.predefined_classes,
      entity_id: item.brandbank_uuid,
      page: 1,
      page_size: PAGE_SIZE
    }).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        const newDataBBS = data.results;
        if (newDataBBS.length > 0) {
          setLabel(newDataBBS[0].name);
        } else {
          setLabel('Nothing found');
        }
      } else {
        setLabel('Nothing found');
      }
    });
  }, []);

  const styles = {
    even: {
      background: '#fff'
    },
    odd: {
      background: '#f2f2f2'
    },
    headerContainer: {
      position: 'relative',
      cursor: 'pointer',
      height: 24,
      margin: '-24px 0 0 auto',
      width: 24
    },
    topPlus: {
      position: 'absolute',
      right: 4,
      top: 4,
      zIndex: 1
    },
    topMinus: {
      position: 'absolute',
      right: 4,
      top: -20,
      zIndex: 2,
      background: 'inherit',
      pointerEvents: 'none'
    },
    wrapper: {
      position: 'relative'
    }
  };

  const clickShare = (item) => {
    onShareClick(
      {
        isShared: true,
        page: 1,
        searchClusterId: item.id,
        selectedJobType: {
          value: {
            project: item.project
          }
        }
      },
      'base'
    );
  };

  const isChecked = selectedItems?.includes(item.id);

  return (
    <div
      style={index % 2 ? styles.even : styles.odd}
      className="d-flex p-3 align-items-center w-100"
      data-testid="cluster-row_parent"
    >
      <div className="pr-3">
        <input
          id={item.id}
          type="checkbox"
          name={item.id}
          onChange={() => handleCheckboxChange(item.id)}
          checked={isChecked}
          data-testid="cluster-row_checkbox"
        />
      </div>
      <div className="w-100">
        <Row>
          <Col sm="12" md="2">
            <div
              style={{
                border: ' 1px solid grey',
                maxHeight: '106px',
                maxWidth: '106px',
                boxSizing: 'border-box',
                position: 'relative'
              }}
              className="d-flex align-items-center justify-content-center h-100 w-100 p-2"
            >
              <div
                style={{ cursor: 'pointer', overflow: 'hidden' }}
                className="d-flex align-items-center justify-content-center h-100 w-100"
                onClick={() => showPreview(item.preview_image_path)}
                data-testid="cluster-row_image"
              >
                <img
                  src={item.preview_image_path}
                  alt=""
                  style={{ maxHeight: '82px', maxWidth: '82px' }}
                />
              </div>
              {!!item.count_of_points && (
                <Button
                  className="ml-1"
                  onClick={() =>
                    setGalleryCropsState({
                      clusterId: item.id,
                      showModal: true
                    })
                  }
                  size="sm"
                  variant="defoult"
                  style={{
                    right: 2,
                    bottom: 2,
                    position: 'absolute',
                    background: '#fff'
                  }}
                  data-testid="cluster-row_gallery-button"
                >
                  {item.original_size &&
                  item.count_of_points !== item.original_size ? (
                    <>
                      {item.count_of_points} ({item.original_size})
                    </>
                  ) : (
                    item.count_of_points
                  )}
                </Button>
              )}
            </div>
          </Col>
          <Col md="7" sm="12">
            <span className="text-muted">cluster uuid: </span>
            {item.id ? (
              <>
                {item.id}
                <Button
                  className="ml-1"
                  onClick={() => clickShare(item)}
                  size="sm"
                  variant="defoult"
                  style={{ padding: '2px 4px' }}
                  data-testid="cluster-row_share-button"
                >
                  Share
                </Button>
              </>
            ) : (
              'unset'
            )}
            <br />
            <span className="text-muted">brandbank label: </span>
            <span>
              {label || <Spinner size="sm" animation="border" role="status" />}
            </span>
            <br />
            <span className="text-muted">brandbank UUID: </span>
            {item.brandbank_uuid ? (
              <>
                {item.brandbank_uuid}
                <Button
                  className="ml-1"
                  onClick={() =>
                    setBBSearchModalState({
                      item: item,
                      showModal: true
                    })
                  }
                  size="sm"
                  variant="defoult"
                  style={{ padding: '2px 4px' }}
                  data-testid="cluster-row_BBS-button"
                >
                  BBS
                </Button>
              </>
            ) : (
              'unset'
            )}
            <br />
            <span className="text-muted">pc UUID: </span>
            {item.predefined_classes || 'unset'}
          </Col>
          <Col md="3" sm="12">
            <span className="text-muted">state: </span>
            {item.state || 'unset'}
            <br />
            <span className="text-muted">substate: </span>
            {item.substate || 'unset'}
            <br />
            <span className="text-muted">labeler_id: </span>
            {item.labeler_id ? (
              <span data-testid="cluster-row_labeler-info">
                {isAdmin ? (
                  <Button
                    className="btn-simple btn-link p-0"
                    type="button"
                    variant="link"
                    onClick={() =>
                      setUserInfoState({
                        userId: item.labeler_id,
                        showModal: true
                      })
                    }
                    data-testid="cluster-row_button_labeler-info"
                  >
                    {item.labeler_id}
                  </Button>
                ) : (
                  item.labeler_id
                )}
              </span>
            ) : (
              'unset'
            )}
            <div className="pr-4">
              <span className="text-muted">reviewer_id: </span>
              {item.reviewer_id ? (
                <span data-testid="cluster-row_reviewer-info">
                  {isAdmin ? (
                    <Button
                      className="btn-simple btn-link p-0"
                      type="button"
                      variant="link"
                      onClick={() =>
                        setUserInfoState({
                          userId: item.reviewer_id,
                          showModal: true
                        })
                      }
                      data-testid="cluster-row_button_reviewer-info"
                    >
                      {item.reviewer_id}
                    </Button>
                  ) : (
                    item.reviewer_id
                  )}
                </span>
              ) : (
                'unset'
              )}
            </div>
          </Col>
        </Row>
        <Accordion>
          <Accordion.Toggle
            eventKey="0"
            as="div"
            style={styles.headerContainer}
          >
            <FontAwesomeIcon icon={faAngleDoubleDown} style={styles.topPlus} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0" style={styles.wrapper}>
            <div style={index % 2 ? styles.even : styles.odd}>
              <FontAwesomeIcon icon={faAngleDoubleUp} style={styles.topMinus} />
              <Row>
                <Col sm="12" md="2">
                  <Button
                    className="mt-1"
                    onClick={() =>
                      setShowSimilarState({
                        item: item,
                        showModal: true
                      })
                    }
                    size="sm"
                    variant="defoult"
                    style={{
                      padding: '2px',
                      width: 'calc(50% - 2px)',
                      maxWidth: 51
                    }}
                    data-testid="cluster-row_show-similar-button"
                  >
                    Show
                    <br />
                    Similar
                  </Button>
                  <Button
                    className="ml-1 mt-1"
                    onClick={() =>
                      setClusterHistoryState({
                        clusterId: item.id,
                        showModal: true
                      })
                    }
                    size="md"
                    variant="defoult"
                    style={{
                      paddingLeft: '2px',
                      paddingRight: '2px',
                      width: 'calc(50% - 2px)',
                      maxWidth: 51
                    }}
                    data-testid="cluster-row_history-button"
                  >
                    <i
                      style={{ fontWeight: 'bold' }}
                      className="fa fa-history"
                    />
                  </Button>
                </Col>
                <Col md="7" sm="12">
                  <span className="text-muted">jobtype id: </span>
                  {item.job_type_id || 'unset'}
                  <br />
                  <span className="text-muted">project: </span>
                  {item.project || 'unset'}
                  <br />
                  <span className="text-muted">
                    imported from featurestore:{' '}
                  </span>
                  {`${item.imported_from_featurestore}`}
                  <br />
                  <span className="text-muted">
                    priority:{' '}
                  </span>
                  {item.priority ? PRIORITIES[item.priority-1].label : 'unset'}
                </Col>
                <Col md="3" sm="12">
                  <span className="text-muted">fix_flag: </span>
                  {`${item.fix_flag}`}
                  <br />
                  <span className="text-muted">reject_flag: </span>
                  {`${item.reject_flag}`}
                  <br />
                  <span className="text-muted">created_at: </span>
                  <span className="text-nowrap">
                    {item.created_at
                      ? moment(item.created_at).format('YYYY-MM-DD HH:mm')
                      : 'unset'}
                    </span>
                  <br />
                  <span className="text-muted">updated_at: </span>
                  <span className="text-nowrap">
                    {item.updated_at
                      ? moment(item.updated_at).format('YYYY-MM-DD HH:mm')
                      : 'unset'}
                  </span>
                </Col>
              </Row>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </div>
  );
}

ClustersRow.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  handleCheckboxChange: PropTypes.func,
  showPreview: PropTypes.func,
  setUserInfoState: PropTypes.func,
  setClusterHistoryState: PropTypes.func,
  setGalleryCropsState: PropTypes.func,
  setShowSimilarState: PropTypes.func,
  setBBSearchModalState: PropTypes.func,
  onShareClick: PropTypes.func
};

ClustersRow.defaultProps = {
  item: {},
  index: 0
};

export default ClustersRow;

import { getUserToken } from 'utils';
import { fetchRetry } from './fetchRetry';

export const BASE_URL = `${process.env.REACT_APP_BACKEND_LABELING}/api/`;

export const getData = (pathname, props, options) => {
  let url = BASE_URL + pathname;
  const token = getUserToken();

  if (props) {
    const queryParams = new URLSearchParams(props).toString();
    url = `${url}?${queryParams}`;
  }
  if (options?.retryOnUnath) {
    return fetchRetry(
      url,
      {
        headers: {
          Authorization: `Token ${token}`
        }
      },
      2
    );
  }
  return fetch(url, {
    headers: {
      Authorization: `Token ${token}`
    }
  });
};

export const putDataWithParams = (pathname, props) => {
  let url = BASE_URL + pathname;
  const token = getUserToken();

  if (props) {
    const queryParams = new URLSearchParams(props).toString();
    url = `${url}?${queryParams}`;
  }

  return fetch(url, {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: new URLSearchParams(props)
  });
};

export const getDataWithToken = (pathname, props) => {
  const token = getUserToken();
  let properies = {
    token
  };

  if (props) {
    properies = { ...props, ...properies };
  }

  return getData(pathname, properies);
};

export const postDataAuthorized = (pathname, props) => {
  const url = BASE_URL + pathname;
  const token = getUserToken();
  return fetch(url, {
    method: 'post',
    body: JSON.stringify(props),
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const postData = (pathname, props) => {
  const url = BASE_URL + pathname;
  return fetch(url, {
    method: 'post',
    body: new URLSearchParams(props)
  });
};

export const putDataAuthorized = (pathname, props) => {
  const url = BASE_URL + pathname;
  const token = getUserToken();

  return fetch(url, {
    method: 'put',
    body: new URLSearchParams(props),
    headers: {
      Authorization: `Token ${token}`
    }
  });
};

export const patchDataAuthorized = (pathname, props) => {
  const url = BASE_URL + pathname;
  const token = getUserToken();
  return fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(props),
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const patchDataAuthorizedWithParams = (pathname, props, body) => {
  let url = BASE_URL + pathname;
  if (props) {
    const queryParams = new URLSearchParams(props).toString();
    url = `${url}?${queryParams}`;
  }
  const token = getUserToken();
  return fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const postDataAuthorizedWithParams = (pathname, props, body) => {
  let url = BASE_URL + pathname;
  if (props) {
    const queryParams = new URLSearchParams(props).toString();
    url = `${url}?${queryParams}`;
  }
  const token = getUserToken();
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const putJsonDataAuthorized = (pathname, props) => {
  const url = BASE_URL + pathname;
  const token = getUserToken();

  return fetch(url, {
    method: 'put',
    body: JSON.stringify(props),
    headers: {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const putJsonDataAuthorizedWithParams = (pathname, body, props) => {
  let url = BASE_URL + pathname;
  const token = getUserToken();

  if (props) {
    const queryParams = new URLSearchParams(props).toString();
    url = `${url}?${queryParams}`;
  }

  return fetch(url, {
    method: 'put',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const postCVSFileAuthorized = (pathname, file, props) => {
  let url = BASE_URL + pathname;
  const token = getUserToken();

  if (props) {
    const queryParams = new URLSearchParams(props).toString();
    url = `${url}?${queryParams}`;
  }

  const data = new FormData();
  data.append('csv_files', file);

  return fetch(url, {
    method: 'post',
    body: data,
    headers: {
      Authorization: `Token ${token}`
    }
  });
};

import React, { FC } from 'react';
import Select from './Select';
import { PRIORITIES } from '../../enums';

type SelectedPriorityT = {
  label: string;
  value: number;
};

export interface SelectPriorityProps {
  selectedPriority: SelectedPriorityT | null;
  setSelectedPriority: React.Dispatch<React.SetStateAction<SelectedPriorityT>>;
  isDisabled: boolean;
  className?: string;
  range?: Array<number>;
}

const SelectPriority: FC<SelectPriorityProps> = ({
  selectedPriority,
  setSelectedPriority,
  isDisabled,
  className,
  range,
}) => {
  const priorityOptions = range
    ? PRIORITIES.filter((el) => range.indexOf(el.value) !== -1)
    : PRIORITIES;

  return (
    <Select
      value={selectedPriority}
      onChange={setSelectedPriority}
      isDisabled={isDisabled}
      className={className}
      options={priorityOptions}
      placeholder="Select Priority"
      isSearchable
      isClearable
    />
  );
};

export default SelectPriority;

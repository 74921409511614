// TODO: type this right
/* eslint-disable react/no-unused-prop-types */
import React, { Children, FC } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';

interface TabViewItemProps {
  tabKey: string | number;
  label: string;
}
export const TabViewItem: FC<TabViewItemProps> = ({ children }) => {
  return <div className="tab"> {children}</div>;
};

interface TabViewProps {
  activeKey: string;
  onSelect?: (key: string | null) => void;
  extraContent: React.ReactNode | null;
}
const TabView: FC<TabViewProps> = ({
  activeKey,
  children,
  onSelect,
  extraContent,
}) => {
  return (
    <div className="sub-body">
      <Row className="pb-1 mb-2">
        <Col xs={12} md={extraContent ? 8 : 12}>
          <Nav
            style={{ width: '100%' }}
            activeKey={activeKey}
            onSelect={(key) => onSelect && onSelect(key)}>
            {Children.map(children, (child: any) => (
              <Nav.Item>
                <Nav.Link eventKey={child.props?.tabKey}>
                  {child.props?.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        {extraContent && (
          <Col xs={12} md={4} className="d-flex justify-content-end">
            {extraContent}
          </Col>
        )}
      </Row>
      {Children.map(children, (child: any) => (
        <div
          className="tabview-tab"
          style={{
            display: child.props?.tabKey === activeKey ? undefined : 'none',
          }}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default TabView;
